// Reusable dialog component
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/joy/Box";

export interface FurnDialogArgs {
  body?: string
  cancelText?: string
  isBusy: boolean
  isOpen: boolean
  okText?: string
  onCancel?: Function
  onClose: Function
  onOk?: Function
  title?: string
}

export function FurnDialog(props: FurnDialogArgs) {
  const {
    body,
    cancelText = "Cancel",
    isBusy,
    isOpen,
    okText = "Ok",
    onCancel,
    onClose,
    onOk,
    title,
  } = props;
  return (
    <Modal
      aria-labelledby={title}
      open={isOpen}
      onClose={() => onClose()}
      sx={modalStyle}
    >
      <Sheet
        variant="outlined"
        sx={sheetStyle}
      >
        <ModalClose variant="plain" sx={modalCloseStyle} />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          style={modalTitleStyle}
        >
          {title}
        </Typography>
        <Typography id="modal-desc" textColor="text.tertiary">
          {body}
        </Typography>
        <Box sx={buttonsContainerStyle}>
          { onOk && (
            <Button onClick={() => onOk()} disabled={isBusy} variant="outlined">
              {okText}
            </Button>
          )}
          { onCancel && (
            <Button onClick={() => onCancel()} disabled={isBusy} variant="outlined">
              {cancelText}
            </Button>
          )}
        </Box>
      </Sheet>
    </Modal>
  );
}

const modalStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

const sheetStyle = {
  borderRadius: "md",
  boxShadow: "lg",
  maxWidth: 500,
  p: 3,
};

const buttonsContainerStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  marginTop: "2rem",
};

const modalCloseStyle = {
  m: 1,
};

const modalTitleStyle = {
  fontWeight: "lg",
  mb: 1,
};
