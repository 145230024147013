/**
 * Configuration
 */

const isLocal = process.env.NODE_ENV === "development";
const config = {
  PUBLIC_URL: isLocal ? "http://localhost:3010" : String(process.env.PUBLIC_URL),
  REACT_APP_DD_APPID: String(process.env.REACT_APP_DD_APPID),
  REACT_APP_DD_ENV: String(process.env.REACT_APP_DD_ENV),
  REACT_APP_DD_SITE: String(process.env.REACT_APP_DD_SITE),
  REACT_APP_DD_TOKEN: String(process.env.REACT_APP_DD_TOKEN),
  REACT_APP_GRAPHQL_URL: isLocal ? "http://localhost:4010/graphql" : String(process.env.REACT_APP_GRAPHQL_URL),
  REACT_APP_PROPELAUTH_URL: String(process.env.REACT_APP_PROPELAUTH_URL),
  REACT_APP_STRIPE_CLIENT_KEY: String(process.env.REACT_APP_STRIPE_CLIENT_KEY) ?? "",
  TITLE: "Furniture App",
};

export type Config = typeof config;
export default config;
