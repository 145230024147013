import { gql } from "@apollo/client";

export const priceFragment = gql`
  fragment priceFragment on Price {
    id
    aiGenerationLimit
    blueprintProduct
    cents
    createdAt
    deletedAt
    externalPriceId
    freeBlueprints
    highlights
    isAnnual
    isMonthly
    isSubscription
    name
    paymentProvider
    product
    userLimit
  }
`;
