import { gql } from "@apollo/client";

export const configFragment = gql`
  fragment configFragment on Configuration {
      id
      aiModels {
        height
        label
        name
        prompt
        negativePrompt
        width
      }
      hasLoggedIn
      hasOnboarded
      subscription {
        endDate
        isActiveSubscription
        nextPaymentDate
        plan
      }
  }
`;
