import { useMemo } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Slider from "@mui/joy/Slider";
import Typography from "@mui/joy/Typography";
import { Mark } from "@mui/base/useSlider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButtonTooltip } from "./iconButtonTooltip";
import { FurnitureControlType, FurnitureValueOption } from "../models/blueprint";

export interface BlueprintControlFields {
  defaultValue?: any
  marks?: Mark[]
  max?: number
  min?: number
  onBlur: Function
  onChange: Function
  options?: FurnitureValueOption[]
  placeholder?: string
  required?: boolean
  step?: number
  tip?: string
  label: string
  type: FurnitureControlType
  value: any
}

export interface BlueprintControlsArgs {
  controls: BlueprintControlFields[]
  isBusy: boolean
}

export interface GroupType {
  [s: string]: [{label: string, value: string}]
}

export function BlueprintControls(props: BlueprintControlsArgs) {
  const { controls, isBusy } = props;

  const controlComponents = useMemo(() => controls.map((ctl, i) => {
    const {
      defaultValue, marks, max, min, onChange, onBlur, options, placeholder, required, tip, label,
      step, type, value,
    } = ctl;
    let ctlComponent;
    switch (type) {
      case "DATE":
        ctlComponent = (
          <FormControl>
            <FormLabel>
              {label}
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              defaultValue={defaultValue}
              disablePast
              onChange={date => onChange(date.toISOString())}
              slotProps={{ textField: { required: true } } }
              value={value}
            />
            </LocalizationProvider>
          </FormControl>
        );
        break;
      case "MULTI_SELECT":
        ctlComponent = (
          <FormControl>
            <FormLabel style={formLabelStyle}>
              {label}
              <IconButtonTooltip isDisabled={isBusy} tip={tip} />
            </FormLabel>
            <Select
              defaultValue={defaultValue}
              disabled={isBusy}
              onChange={(e, vals) => onChange(vals)}
              placeholder={placeholder}
              required={required}
              value={value as [string]}
              multiple
            >
              {options?.map(o => <Option value={o.value} key={o.value}>{o.label}</Option>)}
            </Select>
          </FormControl>
        );
        break;
      case "NUMBER":
        ctlComponent = (
          <FormControl>
            <FormLabel style={formLabelStyle}>
              {label}
              <IconButtonTooltip isDisabled={isBusy} tip={tip} />
            </FormLabel>
            <Input
              defaultValue={defaultValue}
              disabled={isBusy}
              onBlur={onBlur as any}
              onChange={e => onChange(+(e?.target?.value ?? 0))}
              required={required}
              type="number"
              value={value as string}
            />
            {/* Need to resolve color hex issue, When user input any number of digits or letters that is not 6,
            they are accepted but throws error. Below is an attempt to fix idea but to no avail,
            need to move on to other code.
            This is a TODO item. */}
            {/* const colorHex = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
            onChange={e => onChange(e?.target?.value = "color" ? colorHex : +(e?.target?.value ?? 0))} */}
          </FormControl>
        );
        break;
      case "CATEGORY_SELECT":
      case "SELECT":
        ctlComponent = (
          <FormControl>
            <FormLabel style={formLabelStyle}>
              {label}
              <IconButtonTooltip isDisabled={isBusy} tip={tip} />
            </FormLabel>
            <Select
              defaultValue={defaultValue}
              disabled={isBusy}
              onChange={(e, val) => onChange(val)}
              placeholder={placeholder}
              required={required}
              value={value as string}
            >
              {options?.map(o => <Option value={o.value} key={o.value}>{o.label}</Option>)}
            </Select>
          </FormControl>
        );
        break;
      case "SLIDER":
        // ToDo: Enforce required
        ctlComponent = (
          <FormControl>
            <FormLabel style={formLabelStyle}>
              {label}
              <IconButtonTooltip isDisabled={isBusy} tip={tip} />
            </FormLabel>
            <Slider
              aria-label={label}
              defaultValue={defaultValue}
              disabled={isBusy}
              min={min}
              max={max}
              marks={marks ?? [{ value: min ?? 0, label: min }, { value: max ?? 0, label: max }]}
              onChange={(event, sliderVal) => onChange(sliderVal)}
              step={step}
              value={value as unknown as number}
              valueLabelDisplay="auto"
            />
          </FormControl>
        );
        break;
      case "STRING":
        ctlComponent = (
          <FormControl>
            <FormLabel style={formLabelStyle}>
              {label}
              <IconButtonTooltip isDisabled={isBusy} tip={tip} />
            </FormLabel>
            <Input
              defaultValue={defaultValue}
              disabled={isBusy}
              onChange={e => onChange(e?.target?.value)}
              placeholder={placeholder}
              required={required}
              type="text"
              value={value as string}
            />
          </FormControl>
        );
        break;
      default:
        ctlComponent = (
          <Typography>
            Unsupported type
          </Typography>
        );
    }

    return (
      <Grid xs={12} md={6} style={gridCellStyle} key={label}>
        {ctlComponent}
      </Grid>
    );
  }), [controls, isBusy]);

  return (
    <>
      {controlComponents}
    </>
  );
}

const formLabelStyle = {
  marginBottom: 0,
  marginTop: 8,
};

const gridCellStyle = {
  paddingTop: 0,
  paddingBottom: 0,
};
