import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

export function BookDemo() {
  return (
    <Box sx={containerStyle}>
      <Typography color="primary" level="h1" sx={headerStyle}>Book a demo</Typography>
      <Typography sx={textBodyStyle}>
        Your free trial has ended. Schedule a demo to subscribe.
      </Typography>

      <Button onClick={() => window.location.replace("https://thefurnitureapp.com/")}>
        Book a demo
      </Button>
    </Box>
  );
}

const containerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
};

const headerStyle = {
  mb: "1rem",
};

const textBodyStyle = {
  mb: "1rem",
};
