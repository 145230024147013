import { useState, useMemo, useEffect } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import Check from "@mui/icons-material/Check";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import Grid from "@mui/joy/Grid";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Skeleton from "@mui/joy/Skeleton";
import Typography from "@mui/joy/Typography";
import { useCreateCheckoutSession, usePrices } from "../graphql/billing";
import { Checkout } from "../components/checkout";
import { useUser } from "../graphql/user";
import { ProductPriceType } from "../models/billing";

export interface BillingProps {
  title?: string
}

export function Billing(props: BillingProps) {
  const { title } = props;
  const [isActive, setIsActive] = useState(false);
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
  const [tab, setTab] = useState(false);

  const [createCheckoutSession, { data }] = useCreateCheckoutSession();
  const { clientSecret, id: sessionId } = data?.createCheckoutSession ?? {};
  const { user, loading: userLoading } = useUser();
  const { prices, loading } = usePrices();

  const isBusy = useMemo(() => isActive || loading || userLoading, [isActive, loading, userLoading]);

  const {
    currentPlan,
    currentPlanName,
    subEndDate,
    subNextPaymentDate,
  } = useMemo(() => {
    const { subscription } = user.configuration;
    const { plan, endDate, nextPaymentDate } = subscription ?? {};
    const _currentPlan = plan;
    const _subNextPaymentDate = nextPaymentDate && new Date(nextPaymentDate).toLocaleDateString();
    const _subEndDate = endDate && new Date(endDate).toLocaleDateString();
    const currentPlanObj = prices.find(p => p.product === _currentPlan);
    return {
      currentPlan: _currentPlan,
      currentPlanName: currentPlanObj?.name,
      subEndDate: _subEndDate,
      subNextPaymentDate: _subNextPaymentDate,
    };
  }, [prices, user.configuration]);

  const [planType, setPlanType] = useState<ProductPriceType>(currentPlan);

  const planCards = useMemo(() => {
    const filteredPlans = prices.filter(p => p.isSubscription && (tab ? p.isAnnual : p.isMonthly));
    filteredPlans.sort((a, b) => a.cents - b.cents);
    return filteredPlans.map(p => {
      const { highlights, isAnnual, isSubscription, name, product: _planType, cents } = p;
      const isCurrentPlan = currentPlan === _planType;
      let purchaseButton;
      if (isCurrentPlan) {
        purchaseButton = (
          <>
            <Chip size="md" variant="soft" color="success">
              Current Plan
            </Chip>
            {subEndDate && <Typography level="body-xs">ends {subEndDate}</Typography>}
          </>
        );
      }
      else {
        // Only show purchase button for cards other than Free.
        // Also don't show if current plan has already been canceled.
        const isFreePlan = _planType === "CRAFT";
        const buttonLabel = isFreePlan ? "Select" : "Purchase";
        if (!subEndDate || !isFreePlan) {
          purchaseButton = (
            <Button
              variant="outlined"
              color="primary"
              disabled={isBusy}
              onClick={async () => {
                setPlanType(_planType);
                if (_planType !== "CRAFT") {
                  setIsActive(true);
                  // call this in the click handler because the re-render calls it multiple times
                  await createCheckoutSession({ variables: { input: { product: _planType } } });
                }
              }}
              sx={{ ...buttonStyle, width: "100%" }}
            >
              {buttonLabel}
            </Button>
          );
        }
      }
      return (
        <Grid xs={12} sm={6} md={4} lg={3} key={_planType}>
          <Card
            size="lg"
            sx={{ minHeight: "20rem", ...cardStyle }}
            variant={_planType === "CRAFT" ? "plain" : "outlined"}
          >
            <Typography sx={{ typography: { md: "h3", xs: "title-lg" } }}>{name}</Typography>
            <Divider inset="none" />
            <List size="sm" sx={{ mx: "calc(-1 * var(--ListItem-paddingX))" }}>
              {highlights.map((hi, i) => (
                <ListItem key={`${hi}${i}`}>
                  <ListItemDecorator><Check color="primary"/></ListItemDecorator>
                  {hi}
                </ListItem>
              ))}
            </List>
            <Divider inset="none" />
            <CardActions sx={{ display: "flex" }}>
              <Grid container spacing={2} sx={{ alignItems: "center", flex: 1 }}>
                <Grid sm={12} md={6}>
                  <Typography level="title-lg" sx={{ mr: "auto" }}>
                    {`$${cents / 100} `}
                    {isSubscription && (<Typography fontSize="sm" textColor="text.tertiary">
                      / {isAnnual ? "year" : "month"}
                    </Typography>)}
                  </Typography>
                </Grid>
                <Grid sm={12} md={6}>
                  {purchaseButton}
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      );
    });
  }, [createCheckoutSession, currentPlan, isBusy, prices, subEndDate, tab]);

  useEffect(() => {
    if (sessionId) {
      setIsPurchaseOpen(true);
      setIsActive(false);
    }
  }, [sessionId]);

  const purchaseBox = useMemo(() => (
    <Checkout
      clientSecret={clientSecret}
      onOpenClose={isOpen => setIsPurchaseOpen(isOpen)}
      sessionId={sessionId}
      subscriptionPlan={planType}
    />
  ), [clientSecret, planType, sessionId]);

  const billingContent = useMemo(() => (isPurchaseOpen ? purchaseBox : (
    <>
      <Box style={summaryStyle}>
        <Typography level="h1" style={titleStyle} color="primary">{title}</Typography>
        <Skeleton loading={isBusy} animation="wave" sx={relativeStyle}>
          <Typography>Current Plan: {currentPlanName}</Typography>
          {subNextPaymentDate && <Typography>Renews on: {subNextPaymentDate}</Typography>}
          {subEndDate && <Typography>Ends on: {subEndDate}</Typography>}
        </Skeleton>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", margin: 1 }}>
        <Button
          disabled={isBusy}
          onClick={() => setTab(false)}
          sx={buttonStyle}
          variant={(!tab) ? "outlined" : "plain"}
        >
          Monthly
        </Button>
        <Button
          disabled={isBusy}
          onClick={() => setTab(true)}
          sx={buttonStyle}
          variant={(tab) ? "outlined" : "plain"}
        >
          Yearly
        </Button>
      </Box>
      <Grid container spacing={4}>
        {planCards}
      </Grid>
    </>
  // eslint-disable-next-line max-len
  )), [currentPlanName, isBusy, isPurchaseOpen, planCards, purchaseBox, subEndDate, subNextPaymentDate, tab, title]);

  return (
    <Box>
      {billingContent}
    </Box>
  );
}

const titleStyle = {
  marginTop: "2rem",
  marginBottom: "1rem",
};

const summaryStyle = {
  margin: "1rem",
};

const relativeStyle = {
  position: "relative",
  maxWidth: "18rem",
};

const buttonStyle = {
  "&:hover": { backgroundColor: "primary.100" },
  margin: 1,
  width: "150px",
};

const cardStyle = {
  "&:hover": {
    boxShadow: "md",
  },
};
