/* eslint-disable guard-for-in */
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { format, toDate } from "date-fns";
import Box from "@mui/joy/Box";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/joy/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import Typography from "@mui/joy/Typography";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { apolloClient } from "../graphql/apolloClient";
import { clearBlueprintsCache, GET_BLUEPRINTS_CONNECTION, useBlueprintsConnection } from "../graphql/blueprint";
import { FurnitureType } from "../models/blueprint";
import { FileDoc } from "../models/file";
import { useUser } from "../graphql/user";

export interface BlueprintListArgs {
  mode?: "regular" | "trash"
  pageSize?: number // number of items to render at start
  title?: string
  furnitureType?: FurnitureType
}

export function BlueprintList(props: BlueprintListArgs) {
  const { mode = "regular", pageSize: propPageSize = 10, title, furnitureType } = props;
  const [pageSize, setPageSize] = useState<number>(propPageSize);
  const [first, setFirst] = useState<number | undefined>();
  const [last, setLast] = useState<number | undefined>(pageSize);
  const [page, setPage] = useState<number>(0);
  const [after, setAfter] = useState<string | undefined>();
  const [before, setBefore] = useState<string | undefined>();
  const [isReloading, setIsReloading] = useState(false);
  const { user, refetch: refetchUser } = useUser(null, "network-only");
  const {
    // endCursor, hasNextPage, hasPreviousPage, error, startCursor,
    edges, endCursor, loading, refetch, startCursor, totalCount,
  } = useBlueprintsConnection({
    after,
    before,
    deleted: mode === "trash",
    first,
    last,
    queryDoc: GET_BLUEPRINTS_CONNECTION,
    type: furnitureType,
  });

  const isBusy = useMemo(() => isReloading || loading, [loading, isReloading]);

  const handlePaginationModelChange = useCallback(async (model: GridPaginationModel) => {
    if (isBusy) return;
    const { page: newPage, pageSize: newPageSize } = model;
    if (pageSize !== newPageSize) {
      setAfter(undefined);
      setBefore(undefined);
      setPageSize(newPageSize);
      if (last) {
        setLast(newPageSize);
      }
      else {
        setFirst(newPageSize);
      }
      return;
    }
    if (newPage > page) {
      setAfter(undefined);
      setBefore(startCursor);
      setFirst(undefined);
      setLast(pageSize);
      setPage(newPage);
    }
    else if (newPage < page) {
      setAfter(endCursor);
      setBefore(undefined);
      setFirst(pageSize);
      setLast(undefined);
      setPage(newPage);
    }
  }, [endCursor, isBusy, last, page, pageSize, startCursor]);

  const refetchClean = useCallback(async () => {
    setBefore(undefined);
    setIsReloading(true);
    await clearBlueprintsCache(apolloClient);
    await Promise.all([refetch(), refetchUser()]);
    setIsReloading(false);
  }, [refetch, refetchUser]);

  const { rows, columns } = useMemo(() => {
    const _rows: any = edges.map(e => e.node);
    const _columns: GridColDef[] = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 150,
        renderCell: params => <Link to={`/blueprint/${params.row.id}`}>{params.value}</Link>,
      },
      {
        field: "createdAt",
        headerName: "Date Created",
        minWidth: 150,
        valueFormatter: value => format(toDate(value), "MM-dd-yyyy"),
      },
      {
        field: "type",
        headerName: "Furniture Type",
        minWidth: 150,
      },
      {
        field: "hasPurchased",
        headerName: "Purchased?",
        minWidth: 150,
        renderCell: params => (params.value ? <CheckIcon color="primary"/> : null),
      },
      {
        field: "files",
        headerName: "Docs",
        minWidth: 150,
        valueFormatter: (files: FileDoc[]) => (files?.length ? files.length : ""),
      },
    ];

    return { rows: _rows, columns: _columns };
  }, [edges]);

  return (
    <Box>
      <Box sx={titleContainerStyle}>
        <Box>
          <Typography level="h1" style={titleStyle} textColor={"primary.500"}>{title}</Typography>
          <Typography>Remaining Credits: {user?.remainingBlueprintCredits}</Typography>
        </Box>
        <IconButton onClick={refetchClean} variant="plain">
          <ReplayIcon />
        </IconButton>
      </Box>
      <DataGrid
        autoHeight
        checkboxSelection
        columns={columns}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false, // hide id
            },
          },
          pagination: {
            paginationModel: {
              pageSize, // ToDo: init from user config
            },
          },
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        loading={isBusy}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[5, 10, 20]}
        pagination
        paginationMode="server"
        rowCount={totalCount}
        rows={rows}
      />
    </Box>
  );
}

const titleStyle = {
  marginTop: "2rem",
  marginBottom: "1rem",
};

const titleContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
};
