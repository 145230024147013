import { SetStateAction, useCallback, useState } from "react";
import { MuiFileInput } from "mui-file-input";

export type ImageToImageInputProps = {
  disabled: boolean
  label: string
  onFileSelect: Function
  required?: boolean
}

export function FileInput(props: ImageToImageInputProps) {
  const [file, setFile] = useState<File | null>(null);

  const { disabled, label, onFileSelect, required } = props;

  const handleFileChange = useCallback(async (_file: SetStateAction<File | null>) => {
    setFile(_file);
    onFileSelect(_file);
  }, [onFileSelect]);

  return (
    <MuiFileInput
      label={label}
      value={file}
      onChange={handleFileChange}
      disabled={disabled}
      required={required}
      style={{ width: "100%" }}
    />
  );
}
