import { useCallback, useMemo, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Skeleton from "@mui/joy/Skeleton";
import Typography from "@mui/joy/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "../config";
import { useCancelSubscription, useCompleteCheckout } from "../graphql/billing";
import { ProductPriceType } from "../models/billing";

const stripePromise = loadStripe(config.REACT_APP_STRIPE_CLIENT_KEY);

// clientSecret & sessionId are produced from createCheckoutSession which should be called once.
export interface CheckoutProps {
  clientSecret: string | undefined
  onOpenClose: (isOpen: boolean) => void
  sessionId: string | undefined
  subscriptionPlan?: ProductPriceType
}

export function Checkout(props: CheckoutProps) {
  const { clientSecret = null, onOpenClose, sessionId, subscriptionPlan } = props;
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [completeCheckout] = useCompleteCheckout();
  const [cancelSubscription] = useCancelSubscription();

  const onCompleteHandler = useCallback(async () => {
    setIsComplete(true);
    setIsLoading(true);
    await completeCheckout({ variables: { input: { sessionId } } });
    setIsLoading(false);
  }, [completeCheckout, sessionId]);

  const doneHandler = useCallback(() => {
    setIsComplete(false);
    onOpenClose(false);
  }, [onOpenClose]);

  const purchaseContent = useMemo(() => (
    <EmbeddedCheckoutProvider
      options={{
        clientSecret,
        onComplete: onCompleteHandler,
      }}
      stripe={stripePromise}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  ), [clientSecret, onCompleteHandler]);

  const cancelContent = useMemo(() => {
    const cancelHandler = async () => {
      setIsLoading(true);
      await cancelSubscription();
      setIsLoading(false);
      onOpenClose(false);
    };
    return (
      <Sheet style={cancelPlanStyle}>
        <Typography level="h3" sx={{ marginBottom: "1rem" }}>Cancel Subscription</Typography>
        <Typography level="body-lg">
          Downgrading to the free plan may prevent new furniture from being created if the limit has been
          exceeded. You current plan will be active til the end of the current billing cycle.
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Typography level="body-lg" sx={{ margin: "1rem" }}>
            Are you sure you want to cancel?
          </Typography>
          <Box>
            <Skeleton loading={isLoading} animation="wave" sx={{ position: "relative" }}>
              <Button
                onClick={cancelHandler} disabled={isLoading} variant="outlined" size="md" color="danger"
              >
                Yes
              </Button>
              <Button
                onClick={doneHandler} disabled={isLoading} variant="outlined" size="md" sx={{ marginX: "1rem" }}
              >
                No
              </Button>
            </Skeleton>
          </Box>
        </Box>
      </Sheet>
    );
  }, [cancelSubscription, doneHandler, isLoading, onOpenClose]);

  const content = useMemo(() =>
    (subscriptionPlan === "CRAFT" ? cancelContent : purchaseContent),
  [cancelContent, purchaseContent, subscriptionPlan],
  );

  return (
    <Box style={containerStyle}>
      <Sheet style={closeContainerStyle}>
        <IconButton onClick={doneHandler} variant="plain">
          <CancelIcon />
        </IconButton>
      </Sheet>
      {content} {/*  Main checkout content */}
      {isComplete
        && <Sheet style={doneContainerStyle}>
          <Button onClick={doneHandler} variant="solid" size="md">
            Done
          </Button>
        </Sheet>
      }
    </Box>
  );
}

const containerStyle = {
  backgroundColor: "white",
  height: "90vh",
  marginLeft: "-1.5rem",
  marginRight: "-1.5rem",
  marginTop: "-1rem",
};

const closeContainerStyle = {
  display: "flex",
  justifyContent: "end",
};

const cancelPlanStyle = {
  padding: "2rem",
  justifyContent: "end",

};

const doneContainerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "0.5rem",
};
