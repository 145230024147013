import { extendTheme } from "@mui/joy/styles";
import { createTheme } from "@mui/material/styles";

const primaryColor = {
  50: "#e5f2ff",
  100: "#c1ddff",
  200: "#9ac8ff",
  300: "#76b2fe",
  400: "#60a1fe",
  500: "#5491fb",
  600: "#5283ec",
  700: "#4e70d8",
  800: "#4a5fc5",
  900: "#423fa4",
};

const neutral = {
  50: "#ffffff",
  100: "#fafafa",
  200: "#f5f5f5",
  300: "#f0f0f0",
  400: "#dedede",
  500: "#c2c2c2",
  600: "#979797",
  700: "#818181",
  800: "#606060",
  900: "#3c3c3c",
};

const success = {
  50: "#e5f8e4",
  100: "#c1edbe",
  200: "#96e193",
  300: "#62d663",
  400: "#29cc39",
  500: "#00c200",
  600: "#009f00",
  700: "#009f00",
  800: "#008e00",
  900: "#006e00",
};

const warning = {
  50: "#fef7e2",
  100: "#fce9b5",
  200: "#fbdb86",
  300: "#f9ce58",
  400: "#f8c23a",
  500: "#f6b826",
  600: "#f5ab22",
  700: "#f49920",
  800: "#f3891d",
  900: "#f26b18",
};

const danger = {
  50: "#ffeaed",
  100: "#ffcbd0",
  200: "#f29696",
  300: "#e86d6e",
  400: "#f24949",
  500: "#f7352d",
  600: "#e8292c",
  700: "#d61c27",
  800: "#c9111f",
  900: "#ba0013",
};

export const materialTheme = createTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      contrastText: "#ffffff",
      dark: "#395BA5",
      light: "#749BEF",
      main: primaryColor[500],
    },
    secondary: {
      contrastText: "#ffffff",
      dark: "#253F5F",
      light: "#5E7B9F",
      main: "#5283ec",
    },
    error: {
      contrastText: "#ffffff",
      dark: "#A93333",
      light: "#F46D6D",
      main: "#F24949",
    },
    warning: {
      contrastText: "#000000",
      dark: "#AE9039",
      light: "#FAD874",
      main: "#f9cf52",
    },
    success: {
      contrastText: "#000000",
      dark: "#1C8E27",
      light: "#53D660",
      main: "#29CC39",
    },
    info: {
      contrastText: "#ffffff",
      dark: "#2343B2",
      light: "#5B80FF",
      main: "#3361FF",
    },
  },
  typography: {
    fontFamily: "Poppins",
    body1: {
      fontFamily: "Figtree",
    },
    body2: {
      fontFamily: "Figtree",
    },
    button: {
      fontFamily: "Figtree",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[50],
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          ":hover": {
            backgroundColor: primaryColor[100],
          },
        },
      },
    },
  },
});

export const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        mode: "light",
        primary: {
          ...primaryColor,
          500: primaryColor[600],
        },
        neutral,
        success,
        warning,
        danger,
      },
    },
  },
  fontFamily: {
    body: "Poppins",
  },
  components: {
    JoyButton: {
      defaultProps: {
        size: "lg",
      },
      styleOverrides: {
        root: {
          borderRadius: "20px",
          "&:hover": {
            backgroundColor: primaryColor[100],
          },
        },
      },
    },
    JoyCard: {
      styleOverrides: {
        root: {
          borderColor: neutral[400],
        },
      },
    },
  },
});
