import { useEffect, useMemo } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { matchRoutes, useLocation } from "react-router-dom";
import { routes } from "../pages/routes";

export function dataDogInit() {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APPID as string,
    clientToken: process.env.REACT_APP_DD_TOKEN as string,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: process.env.REACT_APP_DD_SITE,
    service: "tfa-web-app",
    env: process.env.REACT_APP_DD_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackViewsManually: true,
    defaultPrivacyLevel: "mask-user-input",
  });
  // datadogRum.addRumGlobalContext("extra_context", window?.dataDogConfig.extra_context )
}

// Compute view name out of routeMatches
export function computeViewName(routeMatches: { route: { path: string } }[]) {
  let viewName = "";
  for (let index = 0; index < routeMatches.length; index++) {
    const routeMatch = routeMatches[index];
    const { path } = routeMatch.route;
    // Skip pathless routes
    if (!path) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (path.startsWith("/")) {
      // Handle absolute child route paths
      viewName = path;
    }
    else {
      // Handle route paths ending with "/"
      viewName += viewName.endsWith("/") ? path : `/${path}`;
    }
  }

  return viewName || "/";
}

// Tracks routes for logging in DataDog
export function RouteTracker() {
  const location = useLocation();

  const rrRoutes = useMemo(() => {
    const processedRoutes = [];
    const routeKeys = Object.keys(routes);
    for (const rk of routeKeys) {
      processedRoutes.push({ path: routes[rk].route });
    }
    return processedRoutes;
  }, []);

  useEffect(() => {
    const routeMatches = matchRoutes(rrRoutes, location.pathname);
    const viewName = routeMatches && computeViewName(routeMatches);
    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [location.pathname, rrRoutes]);

  return null;
}
