import { useState } from "react";
import { ImageGenerator } from "../components/imageGenerator";
import { AiGeneratorContext, ImageEditorValuesType, ImageGeneratorValuesType } from "../hooks/useAiGeneratorTabs";
import { GeneratorMode } from "../models/image";

export function AI() {
  const [editValues, setEditValues] = useState<ImageEditorValuesType>({
    height: 768,
    width: 1024,
  });
  const [generatorValues, setGeneratorValues] = useState<ImageGeneratorValuesType>({
    height: 768,
    mode: GeneratorMode.TEXT,
    prompt: "",
    width: 1024,
  });

  return (
    <AiGeneratorContext.Provider value={{
      editValues, setEditValues, generatorValues, setGeneratorValues,
    }}>
      <ImageGenerator />
    </AiGeneratorContext.Provider>
  );
}
