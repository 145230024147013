import { ReactNode, useState } from "react";
import IconButton from "@mui/joy/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/joy/Typography";
import InfoIcon from "@mui/icons-material/Info";

export interface IconButtonTooltipProps {
  icon?: ReactNode
  isDisabled?: boolean
  tip?: string
}

export function IconButtonTooltip(props: IconButtonTooltipProps) {
  const { icon = <InfoIcon fontSize="small" />, isDisabled, tip } = props;
  const [infoAnchorEl, setInfoAnchorEl] = useState<HTMLButtonElement | null>(null);

  if (!tip) return null;

  const infoHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInfoAnchorEl(event.currentTarget);
  };
  const infoOpen = Boolean(infoAnchorEl);

  const closeInfo = () => {
    setInfoAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="name"
        disabled={isDisabled}
        onClick={infoHandler}
      >
        {icon}
      </IconButton>

      <Popover
        anchorEl={infoAnchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        open={infoOpen}
        onClose={closeInfo}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Typography sx={{ m: 1 }}>
          {tip}
        </Typography>
      </Popover>
    </>
  );
}
