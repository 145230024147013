import { FetchPolicy, gql, useMutation, useQuery } from "@apollo/client";
import { CheckoutSession, CompleteCheckout, Price } from "../models/billing";
import { priceFragment } from "./fragment/billingFragments";
import { GET_USER } from "./user";
import { useMemo } from "react";
import { GET_BLUEPRINTS, GET_BLUEPRINTS_CONNECTION } from "./blueprint";

export const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(input: $input) {
      clientSecret
      id
      isCredit
    }
  }
`;

export function useCreateCheckoutSession() {
  return useMutation<{ createCheckoutSession: CheckoutSession }>(CREATE_CHECKOUT_SESSION, {
    refetchQueries: [
      GET_BLUEPRINTS,
      GET_BLUEPRINTS_CONNECTION,
      "blueprints", // Query name
      GET_USER,
      "user", // Query name
    ],
  });
}

// --------------------------------------------------------

export const COMPLETE_CHECKOUT = gql`
  mutation completeCheckout($input: CompleteCheckoutInput!) {
    completeCheckout(input: $input) {
      customerEmail
      status
    }
  }
`;

export function useCompleteCheckout() {
  return useMutation<{ completeCheckout: CompleteCheckout }>(COMPLETE_CHECKOUT, {
    refetchQueries: [
      GET_BLUEPRINTS,
      GET_BLUEPRINTS_CONNECTION,
      "blueprints", // Query name
      GET_USER,
      "user", // Query name
    ],
  });
}

// --------------------------------------------------------
export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription {
    cancelSubscription {
      success
    }
  }
`;

export function useCancelSubscription() {
  return useMutation<{ success: boolean }>(CANCEL_SUBSCRIPTION, {
    refetchQueries: [
      GET_USER,
      "user", // Query name
    ],
  });
}

// --------------------------------------------------------
export const GET_PRICES = gql`
  query {
    prices {
      ...priceFragment
    }
  }
  ${priceFragment}
`;

export function usePrices(
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error, loading } = useQuery<{ prices: Price[] | null }>(GET_PRICES, {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });

  const prices = useMemo(() => data?.prices ?? [], [data?.prices]);

  return { prices, error, loading };
}
