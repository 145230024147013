import * as React from "react";
import Sheet from "@mui/joy/Sheet";
import CircularProgress from "@mui/joy/CircularProgress";

export function Loader() {
  return (
    <Sheet
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
     <CircularProgress size="lg" />
    </Sheet>
  );
}
