export const errorMessages = {
  aiExceeded: [
    "The number of AI images has exceeded the plan month's allotment.",
    "Please upgrade your plan or contact support.",
  ],
  aiGenerationError: [
    "Something went wrong generating the image.",
  ],
  imageRequired: [
    "Please supply an image.",
  ],
  unauthorized: [
    "Feature is not allowed for this account",
  ],
};
