/**
 * Configuration settings context
 */
import { createContext, useContext } from "react";
import config, { Config } from "../config";

export const ConfigContext = createContext<Config | undefined>(config);

// Custom hook for application configuration settings
export function useConfig(): Config {
  const _config = useContext(ConfigContext);
  if (!_config) {
    throw new Error("Configuration context not initialized!");
  }
  return _config;
}
