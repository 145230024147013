import { Blueprint } from "./blueprint";

export interface Image {
  blueprints: Blueprint[]
  createdAt: string
  deletedAt: string
  favorite: boolean
  generator: ImageGenerator
  height: number
  id: string
  name: string
  sourceUrl: string
  width: number
}

export interface ImageGenerator {
  eta: number
  mode: GeneratorMode | EditMode
  model: "FURNITURE1" | "FURNITURE2"
  progress: number
  progressThumb: string
  prompt: string
  negativePrompt: string
  seed: number
  status: "ERROR" | "COMPLETE" | "PENDING"
}

export interface ImageConnection {
  edges: ImageEdge[],
  pageInfo: {
    endCursor: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor: string
  }
  totalCount: number
}

export interface ImageEdge {
  cursor: string
  node: Image
}

export enum GeneratorMode {
  // "IMAGE" = "IMAGE",
  "TEXT" = "TEXT",
}

export enum EditMode {
  // "DOODLE" = "DOODLE",
  "INPAINT" = "INPAINT",
  "OUTPAINT" = "OUTPAINT",
  "REMOVE_BACKGROUND" = "REMOVE_BACKGROUND",
}

export type UploadMethod = "GET" | "POST" | "PUT"

export interface UploadUrlInput {
  filename: string
  method?: UploadMethod
}

export interface UploadUrlPayload {
  uploadUrl: string
  url: string
}
