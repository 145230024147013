import { imageFileResizer } from "@peacechen/react-image-file-resizer";
import { Persona } from "../models/user";

// Convert an arbitrary string such as a name into #RGB hex color code
export function stringToColor(nameString: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < nameString.length; i += 1) {
    hash = nameString.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

// Convert string to Title Case. Also replace "_" with space.
export function titleCase(str: string) {
  return str.toLowerCase().replace("_", " ").replace(/\b\w/g, s => s.toUpperCase());
}

// Parse a string to a number. If not a valid number, return undefined.
export function toIntOrUndefined(value: string) {
  const num = parseInt(value, 10);
  if (isNaN(num)) return undefined;
  return num;
}

// Promisify canvas toBlob()
export async function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
  return new Promise((resolve: BlobCallback) => {
    canvas.toBlob(resolve);
  });
}

// --------------------------------------------------------
// Promisify image Resizer
export type ResizeImageArgs = {
  compressFormat?: "png" | "jpeg" | "webp"
  exact?: boolean
  file: File
  maxHeight: number
  maxWidth: number
  outputType?: "base64" | "blob" | "file"
  quality?: number
}

export async function resizeImage(args: ResizeImageArgs) {
  const {
    compressFormat = "png",
    exact,
    file,
    maxHeight,
    maxWidth,
    outputType = "blob",
    quality = 100,
  } = args;

  let minHeight: number | undefined;
  let minWidth: number | undefined;
  if (exact) {
    // Force resize to the provided width & height
    minHeight = maxHeight;
    minWidth = maxWidth;
  }
  return imageFileResizer({
    compressFormat,
    file,
    maxWidth,
    maxHeight,
    quality,
    outputType,
    minWidth,
    minHeight,
  });
}

// --------------------------------------------------------
// Promisify image dimensions
export type GetImageDimensionsFromFileResult = {
  height: number
  url: string
  width: number
}
export async function getImageDimensionsFromFile(file: File): Promise<GetImageDimensionsFromFileResult> {
  if (!file.type.startsWith("image")) throw new Error("Unsupported image");
  const fileAsDataURL = window.URL.createObjectURL(file);
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        url: fileAsDataURL,
        width: img.width,
      });
    };
    img.src = fileAsDataURL;
  });
}

// --------------------------------------------------------
export function personasFormatter(personas: Persona[] = []) {
  return personas.map(p => {
    switch (p) {
      case Persona.MAKER:
        return "Maker";
      case Persona.INDUSTRIAL_DESIGNER:
        return "Industrial Designer";
      case Persona.INTERIOR_DESIGNER:
        return "Interior Designer";
      default:
        return "Unknown";
    }
  });
}
