/**
 * AI Generator Tabs context allows other components set the active tab and send information to the generator
 */
import { createContext, useContext } from "react";
import { EditMode, GeneratorMode } from "../models/image";

export interface ImageEditorValuesType {
  height?: number
  isRegenerate?: boolean
  mode?: EditMode
  model?: "FURNITURE" | undefined
  name?: string
  negativePrompt?: string
  prompt?: string
  seed?: number
  width?: number
}

export interface ImageGeneratorValuesType {
  height: number
  isRegenerate?: boolean
  mode?: GeneratorMode
  model?: "FURNITURE" | undefined
  name?: string
  negativePrompt?: string
  prompt: string
  seed?: number
  width: number
}

export interface AiGeneratorParams {
  editValues: ImageEditorValuesType
  generatorValues: ImageGeneratorValuesType
  setEditValues: Function
  setGeneratorValues: Function
}

export const defaultEditorValues = {
  mode: EditMode.REMOVE_BACKGROUND,
};

export const defaultGeneratorValues = {
  height: 768,
  mode: GeneratorMode.TEXT,
  prompt: "",
  width: 1024,
};

export const AiGeneratorContext = createContext<AiGeneratorParams>({
  editValues: defaultEditorValues,
  generatorValues: defaultGeneratorValues,
  setEditValues: () => {},
  setGeneratorValues: () => {},
});

// Custom hook to read image tabs
export function useAiGeneratorTabs(): AiGeneratorParams {
  const aiGeneratorParams = useContext(AiGeneratorContext);
  return aiGeneratorParams;
}
