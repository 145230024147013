import { createContext } from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";

let token = "";
export const tokenContext = createContext({
  setToken: (tkn: string) => {
    token = tkn;
  },
  token,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: token ? `Bearer ${token}` : "",
  },
}));

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  batchMax: 5,
  batchInterval: 50, // Wait no more than 50ms after first batched operation
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: { errorPolicy: "all" },
  },
  connectToDevTools: true,
});

export function getSubErrors(err: any): Error[] | null {
  // Old style network errors
  if (err.errors) {
    return err.errors as Error[];
  }

  // Apollo errors
  if (err) {
    return err.clientErrors.concat(err.graphQLErrors, err.networkError ?? []);
  }

  return null;
}
