import { gql } from "@apollo/client";
import { imageMinFragment } from "../image";

export const blueprintFragment = gql`
  fragment blueprintFragment on Blueprint {
    id
    accessibility
    backHeight
    baseMaterial
    bedFrame
    bedSize
    color
    countertopMaterial
    createdAt
    createdBy {
      id
    }
    deletedAt
    depth
    doorLayout
    doorStyle
    files {
      id
      filename
      description
      updatedAt
      url
    }
    furnitureEdge
    furnitureStyle
    hasPurchased
    headboardMaterial
    headboardMounting
    height
    images {
      ...imageMinFragment
    }
    legMaterial
    legStyle
    length
    name
    notes
    planDocs
    priceHigh
    priceLow
    seatHeight
    tabletopMaterial
    thickness
    timeEnd
    timeStart
    type
    updatedAt
    width
    zipCode
  }
  ${imageMinFragment}
`;
