import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListItem } from "@mui/joy";
import Modal, { ModalProps, modalClasses } from "@mui/joy/Modal";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import { useLogoutFunction } from "@propelauth/react";
import { useUser } from "../graphql/user";
import { User } from "../models/user";
import { routes } from "../pages/routes";
import { stringToColor } from "../util/common";
import tfaLogo from "../assets/TheFurnitureApp_logo.png";

export interface DrawerProps extends Omit<ModalProps, "children"> {
  children: React.ReactNode
  logo?: string
  position?: "left" | "right" | "top" | "bottom"
  size?: number | string
  title?: string
}

export function Drawer({
  children,
  logo = "",
  position = "left",
  size,
  sx,
  title = "Title",
  ...props
}: DrawerProps) {
  const topNavTitle = useMemo(() => {
    if (logo) {
      return (
        <Box
          alt="logo"
          component="img"
          src={logo}
          style={logoStyle}
          sx={{ margin: 2 }}
        />
      );
    }
    return (
      <Typography fontSize="lg" fontWeight="lg" sx={{ flex: 1 }}>
        {title}
      </Typography>
    );
  }, [logo, title]);

  return (
    <Modal
      keepMounted
      sx={[
        {
          transitionProperty: "visibility",
          transitionDelay: props.open ? "0s" : "300ms",
          [`& .${modalClasses.backdrop}`]: {
            opacity: props.open ? 1 : 0,
            transition: "opacity 0.3s ease",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Sheet
        sx={{
          px: 2,
          py: 1.5,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          overflow: "auto",
          ...(position === "left" && {
            left: 0,
            transform: props.open ? "translateX(0)" : "translateX(-100%)",
          }),
          ...(position === "right" && {
            right: 0,
            transform: props.open ? "translateX(0)" : "translateX(100%)",
          }),
          ...(position === "top" && {
            top: 0,
            transform: props.open ? "translateY(0)" : "translateY(-100%)",
          }),
          ...(position === "bottom" && {
            bottom: 0,
            transform: props.open ? "translateY(0)" : "translateY(100%)",
          }),
          height: position.match(/(left|right)/) ? "100%" : size,
          width: position.match(/(top|bottom)/) ? "100vw" : size,
          boxShadow: "md",
          transition: "transform 0.3s ease",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {topNavTitle}
          <ModalClose sx={{ position: "top" }} />
        </Box>
        <Divider sx={{ mt: 1, mb: 1.5 }} />
        {children}
      </Sheet>
    </Modal>
  );
}

export function NavBar() {
  const logoutFn = useLogoutFunction();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { firstName, lastName, email } = user as User;
  const name = firstName ? `${firstName} ${lastName ?? ""}` : email;

  return useMemo(() => {
    const onNavClick = (route: string) => {
      setOpen(false);
      navigate(route);
    };

    const navButtons = [
      routes.home,
      routes.aiGenerate,
      routes.aiImages,
      routes.aiImagesFav,
      routes.blueprintList,
      routes.billing,
    ];

    const children = (
      <>
        <List style={listBodyStyle}>
          {navButtons.map(nb => (
            <React.Fragment key={nb.name}>
              <ListItem style={{ ...listItemStyle }}>
                <Button
                  color="primary"
                  onClick={() => onNavClick(nb.route)}
                  startDecorator={<nb.icon />}
                  style={buttonStyle}
                  variant="plain"
                >
                  {nb.name}
                </Button>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </>
    );

    function stringAvatar(_name: string = " ") {
      const nameParts = _name.split(" ");
      return {
        sx: {
          alignSelf: "center",
          bgcolor: stringToColor(_name),
          color: "white",
          size: "sm",
        },
        children: `${nameParts?.[0]?.[0] ?? ""}${nameParts?.[1]?.[0] ?? ""}`,
      };
    }

    return (
      <>
        <AppBar >
          <Box style={toolbarStyle}>
            <IconButton
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>

            {/* Middle separator  */}
            <Box />

            <Box sx={{ display: "flex" }}>
              <Dropdown>
                <MenuButton variant="plain">
                  <Avatar {...stringAvatar(name)} />
                </MenuButton>
                <Menu>
                <MenuItem>
                    <Button
                      onClick={() => navigate(routes.profile.route)}
                      startDecorator={<routes.profile.icon />}
                      style={userMenuButtonStyle}
                    >
                      Profile
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      onClick={() => logoutFn(true)}
                      startDecorator={<LogoutIcon />}
                      style={userMenuButtonStyle}
                    >
                      Sign Out
                    </Button>
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Box>
          </Box>
        </AppBar>

        <Drawer
          children={children}
          logo={tfaLogo}
          onClose={() => setOpen(false)}
          open={open}
          title="Furniture App"
        />
      </>
    );
  }, [logoutFn, name, navigate, open]);
}

// Styles
const listBodyStyle = {
  height: "auto",
};

const listItemStyle = {
  paddingLeft: 0,
};

const buttonStyle = {
  fontWeight: 400,
  justifyContent: "flex-start",
  paddingLeft: 4,
  width: "100%",
};

const userMenuButtonStyle = {
  ...buttonStyle,
  backgroundColor: "transparent",
  color: "#000",
};

const toolbarStyle = {
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
};

const logoStyle = {
  width: "181px",
  height: "181px",
};
