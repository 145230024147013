import { useMemo } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Slider from "@mui/joy/Slider";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import { Mark } from "@mui/base/useSlider";
import { IconButtonTooltip } from "./iconButtonTooltip";
import { FileInput } from "./fileInput";
import { GeneratorMode } from "../models/image";

export interface OptionType {
  label: string
  value: string
}

export type ControlType =
  "fileSelect" | "number" | "painter" | "painterMask" | "select" | "slider" | "text"

export interface ControlFields {
  filterModes?: string[]
  marks?: Mark[]
  max?: number
  min?: number
  onBlur?: Function
  onChange: Function
  options?: OptionType[]
  placeholder?: string
  required?: boolean
  step?: number
  tip?: string
  title: string
  type: ControlType
  value: string | number | boolean | GeneratorMode | undefined
}

export interface AiControlsArgs {
  controls: ControlFields[]
  defaultMode?: string
  isBusy: boolean
  // painterWidth?: number
  selectedMode: string
}

export function AiControls(props: AiControlsArgs) {
  const { controls, defaultMode, isBusy, selectedMode } = props;

  const controlComponents = useMemo(() => {
    const filteredControls = controls.filter(ctl => {
      if (ctl.filterModes) {
        return ctl.filterModes.includes(selectedMode ?? defaultMode);
      }
      // No filterModes means show in every mode.
      return true;
    });

    return filteredControls.map((ctl, i) => {
      const {
        marks, max, min, onBlur, onChange, options, placeholder, required, step, tip, title, type, value,
      } = ctl;
      let ctlComponent;
      switch (type) {
        case "fileSelect":
          ctlComponent = (
            <FormControl>
              <FormLabel style={formLabelStyle}>
                {title}
                <IconButtonTooltip isDisabled={isBusy} tip={tip} />
              </FormLabel>
              <FileInput
                disabled={isBusy}
                label={title}
                onFileSelect={onChange}
              />
            </FormControl>
          );
          break;
        case "number":
          ctlComponent = (
            <FormControl>
              <FormLabel style={formLabelStyle}>
                {title}
                <IconButtonTooltip isDisabled={isBusy} tip={tip} />
              </FormLabel>
              <Input
                disabled={isBusy}
                onBlur={onBlur as any}
                onChange={onChange as any}
                required={required}
                type="number"
                value={value as string}
              />
            </FormControl>
          );
          break;
        case "painter":
          ctlComponent = (
            <FormControl>
              <FormLabel style={formLabelStyle}>
                {title}
                <IconButtonTooltip isDisabled={isBusy} tip={tip} />
              </FormLabel>
              {/* {painterInstance} */}
            </FormControl>
          );
          break;
        case "painterMask":
          ctlComponent = (
            <FormControl>
              <FormLabel style={formLabelStyle}>
                {title}
                <IconButtonTooltip isDisabled={isBusy} tip={tip} />
              </FormLabel>
              {/* {painterMaskInstance} */}
            </FormControl>
          );
          break;
        case "select":
          ctlComponent = (
            <FormControl>
              <FormLabel style={formLabelStyle}>
                {title}
                <IconButtonTooltip isDisabled={isBusy} tip={tip} />
              </FormLabel>
              <Select
                disabled={isBusy}
                onChange={onChange as any}
                placeholder={placeholder}
                required={required}
                value={value as string}
              >
                {options?.map(o => <Option value={o.value} key={o.value}>{o.label}</Option>)}
              </Select>
            </FormControl>
          );
          break;
        case "slider":
          ctlComponent = (
            <FormControl>
              <FormLabel style={formLabelStyle}>
                {title}
                <IconButtonTooltip isDisabled={isBusy} tip={tip} />
              </FormLabel>
              <Slider
                aria-label={title}
                disabled={isBusy}
                min={min}
                max={max}
                marks={marks}
                onChange={onChange as any}
                step={step}
                value={value as unknown as number}
                valueLabelDisplay="auto"
              />
            </FormControl>
          );
          break;
        case "text":
          ctlComponent = (
            <FormControl>
              <FormLabel style={formLabelStyle}>
                {title}
                <IconButtonTooltip isDisabled={isBusy} tip={tip} />
              </FormLabel>
              <Textarea
                disabled={isBusy}
                onBlur={onBlur as any}
                onChange={onChange as any}
                required={required}
                value={value as string}
              />
            </FormControl>
          );
          break;
        default:
          ctlComponent = (
            <Typography>
              Unsupported control type
            </Typography>
          );
      }

      return (
        <Grid xs={12} style={gridCellStyle} key={title}>
          {ctlComponent}
        </Grid>
      );
    });
  }, [controls, defaultMode, isBusy, selectedMode]);

  return (
    <>
      {controlComponents}
    </>
  );
}

const formLabelStyle = {
  marginBottom: 0,
  marginTop: 8,
};

const gridCellStyle = {
  paddingTop: 0,
  paddingBottom: 0,
};
