import {
  ApolloClient, DocumentNode, FetchPolicy, NormalizedCacheObject, gql, useMutation, useQuery,
} from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Blueprint, BlueprintConnection, FurnitureDesignSpec, FurnitureType,
} from "../models/blueprint";
import { blueprintFragment } from "./fragment/blueprintFragments";
import { getSubErrors } from "./apolloClient";

export const GET_BLUEPRINTS = gql`
  query blueprints($ids: [ID!]!) {
    blueprints(ids: $ids) {
      ...blueprintFragment
    }
  }
  ${blueprintFragment}
`;

export interface useBlueprintsArgs {
  fetchPolicy?: FetchPolicy
  ids: string[]
}

// Hook version of fetching blueprints
export function useBlueprints(args: useBlueprintsArgs) {
  const { fetchPolicy, ids } = args;
  const { data, error, loading, refetch } = useQuery<{ blueprints: Blueprint[] }>(GET_BLUEPRINTS, {
    variables: { ids },
    fetchPolicy,
  });
  const blueprints = useMemo(() => data?.blueprints ?? [], [data]);
  return { blueprints, error, loading, refetch };
}

// Imperative version of fetching blueprints
export interface getBlueprintsArgs {
  client: ApolloClient<NormalizedCacheObject>
  fetchPolicy?: FetchPolicy
  ids: string[]
}

export async function getBlueprints(args: getBlueprintsArgs) {
  const { client, fetchPolicy, ids } = args;
  try {
    const { data, errors, loading } = await client.query({
      query: GET_BLUEPRINTS,
      variables: { ids },
      fetchPolicy,
    });
    const blueprints: Blueprint[] = data?.blueprints ?? [];
    return { blueprints, errors, loading };
  }
  catch (e: any) {
    return {
      blueprints: [],
      errors: getSubErrors(e),
      loading: false,
    };
  }
}

// --------------------------------------------------------
export const CREATE_BLUEPRINT = gql`
  mutation createBlueprint($input: CreateBlueprintInput!) {
    createBlueprint(input: $input) {
      ...blueprintFragment
    }
  }
  ${blueprintFragment}
`;

export function useCreateBlueprint() {
  return useMutation<{ createBlueprint: Blueprint }>(CREATE_BLUEPRINT, {
    refetchQueries: [
      GET_BLUEPRINTS_CONNECTION,
      "blueprints", // Query name
    ],
  });
}

// --------------------------------------------------------
export const UPLOAD_BLUEPRINT_FILE = gql`
  mutation uploadBlueprintFile($input: UploadBlueprintFileInput!) {
    uploadBlueprintFile(input: $input) {
      uploadUrl
      url
    }
  }
`;

export function useUploadBlueprintFile() {
  return useMutation<{ uploadBlueprintFile: { uploadUrl: string, url: string }  }>(UPLOAD_BLUEPRINT_FILE);
}

// --------------------------------------------------------
export const DELETE_BLUEPRINT_FILES = gql`
  mutation deleteBlueprintFiles($input: DeleteBlueprintFilesInput!) {
    deleteBlueprintFiles(input: $input)
  }
`;

export function useDeleteBlueprintFiles(refetchQueries?: DocumentNode[]) {
  return useMutation<{ deleteBlueprintFiles: Boolean }>(DELETE_BLUEPRINT_FILES, {
    refetchQueries,
  });
}

// --------------------------------------------------------
export const FURNITURE_DESIGN_SPECS = gql`
  {
    furnitureDesignSpecs {
      label
      fields {
        default
        help
        label
        max
        min
        multi
        name
        required
        step
        type
        values {
          cat
          label
          value
        }
      }
      type
    }
  }
`;

// Hook version of fetching furniture specs
export interface getFurnitureDesignSpecArgs {
  fetchPolicy?: FetchPolicy
}
export function useFurnitureDesignSpecs(args: getFurnitureDesignSpecArgs) {
  const { fetchPolicy = "network-only" } = args;
  const { data, error, loading, refetch } = useQuery<{ furnitureDesignSpecs: [FurnitureDesignSpec] }>(
    FURNITURE_DESIGN_SPECS, { fetchPolicy });
  const furnitureDesignSpecs = useMemo(() => data?.furnitureDesignSpecs ?? [], [data]);
  return { furnitureDesignSpecs, error, loading, refetch };
}

// --------------------------------------------------------
export const GET_BLUEPRINTS_CONNECTION = gql`
  query blueprintConnection($after: String, $before: String, $deleted: Boolean,
    $first: Int, $last: Int, $type: FurnitureType
  ) {
    blueprintConnection(after: $after, before: $before, deleted: $deleted,
      first: $first, last: $last, type: $type)
    {
      edges {
        cursor
        node {
          ...blueprintFragment
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
  ${blueprintFragment}
`;

export interface useBlueprintsConnectionArgs {
  after?: string
  before?: string
  deleted?: boolean
  fetchPolicy?: FetchPolicy
  first?: number
  last?: number
  queryDoc?: DocumentNode
  type?: FurnitureType
}

export function useBlueprintsConnection(args: useBlueprintsConnectionArgs) {
  const {
    after,
    before,
    deleted,
    fetchPolicy = "cache-first",
    first,
    last,
    queryDoc = GET_BLUEPRINTS_CONNECTION,
    type,
  } = args;
  const { data, error, loading, refetch: refetchQuery } = useQuery<{ blueprintConnection: BlueprintConnection }>(
    queryDoc,
    {
      variables: { after, before, deleted, first, last, type },
      fetchPolicy,
    },
  );

  const [blueprintConnection, setBlueprintConnection] = useState<BlueprintConnection>(
    { edges: [], pageInfo: {} as any, totalCount: 0 },
  );
  useEffect(() => {
    if (data?.blueprintConnection) {
      setBlueprintConnection(data.blueprintConnection);
    }
  }, [blueprintConnection, data]);

  const { edges, totalCount, pageInfo } = blueprintConnection;
  const { endCursor, hasNextPage, hasPreviousPage, startCursor } = pageInfo;

  const refetch = useCallback(async () => {
    const result = await refetchQuery();
    return result;
  }, [refetchQuery]);

  return {
    endCursor,
    hasNextPage,
    hasPreviousPage,
    error,
    loading,
    edges,
    refetch,
    startCursor,
    totalCount,
  };
}

// --------------------------------------------------------
export async function clearBlueprintsCache(client: ApolloClient<NormalizedCacheObject>) {
  await client.refetchQueries({
    updateCache(cache) {
      cache.evict({ id: "ROOT_QUERY", fieldName: "blueprints", broadcast: false });
      cache.evict({ id: "ROOT_QUERY", fieldName: "blueprintConnection", broadcast: false });
      cache.evict({ id: "Blueprint", broadcast: false });
      cache.gc();
    },
  });
}
