import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useUser } from "../graphql/user";
import { Persona } from "../models/user";
import { personasFormatter } from "../util/common";
import { useMemo } from "react";

// ToDo: fetch columns from API config
const columns: GridColDef[] = [
  {
    field: "id",
  },
  {
    field: "firstName",
    headerName: "First Name",
    minWidth: 100,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    minWidth: 100,
  },
  {
    field: "personas",
    flex: 1,
    headerName: "Title",
    minWidth: 100,
    valueFormatter: (personas: Persona[]) => personasFormatter(personas),
  },
  {
    field: "email",
    flex: 1,
    headerName: "Email",
    minWidth: 100,
  },
  {
    field: "role",
    headerName: "Authority",
    minWidth: 100,
  },
];
const  pageSizeOptions = [10, 25, 50];

export function Profile() {
  const { user } = useUser();
  const rows = user?.organization?.users ?? [];
  const totalCount = rows.length;

  const { formattedName, formattedPersonas } = useMemo(() => ({
    formattedName: `${user.firstName ?? ""} ${user.lastName ?? ""}`,
    formattedPersonas: personasFormatter(user.personas),
  }), [user.firstName, user.lastName, user.personas]);

  return (
    <Box>
      <Typography level="h1" style={titleStyle}>User Profile</Typography>
      <Card sx={{ marginBottom: "2rem" }}>
        <Typography>Name: {formattedName}</Typography>
        <Typography>Email: {user.email}</Typography>
        <Typography>Title: {formattedPersonas}</Typography>
        <Typography>Authority: {user.role}</Typography>
        </Card>

      <Typography level="h2" style={titleStyle}>Members</Typography>
      <DataGrid
        autoHeight
        // checkboxSelection
        columns={columns}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false, // hide
            },
          },
        }}
        pageSizeOptions={pageSizeOptions}
        rows={rows}
        rowCount={totalCount}
      />
    </Box>
  );
}

const titleStyle = {
  margin: "2rem 0 1rem",
};
