import { useParams } from "react-router-dom";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { BlueprintOrderForm } from "../components/blueprintOrderForm";
import { BlueprintView } from "../components/blueprintView";

export interface BlueprintProps {
  create?: boolean
}

export function Blueprint(props: BlueprintProps) {
  const { create } = props;
  const { id = "" } = useParams();

  if (create) {
    if (!id) {
      return (
        <Box>
          <Typography level="title-md" fontWeight="lg" mb={1}>
            Error: Image ID required
          </Typography>
        </Box>
      );
    }
    return (
      <div>
        <BlueprintOrderForm imageId={id} />
      </div>
    );
  }

  return (
    <div>
      <BlueprintView id={id} />
    </div>
  );
}
