import { useState } from "react";
import IconButton from "@mui/joy/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { useCopyToClipboard } from "usehooks-ts";

interface CopyIconProps {
  value: string
}

export const CopyIcon: React.FunctionComponent<CopyIconProps> = ({ value }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [copyVal, copy] = useCopyToClipboard();
  const [isClicked, setIsClicked] = useState(false);
  let clickTimer: NodeJS.Timeout | undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsClicked(true);
    copy(value);
    if (clickTimer) {
      clearTimeout(clickTimer);
    }
    clickTimer = setTimeout(() => {
      setIsClicked(false);
      clickTimer = undefined;
    }, 2000);
  };

  return (
    <IconButton
      onClick={handleClick}
      sx={{ float: "right" }}
    >
      {isClicked ? <LibraryAddCheckIcon/> : <ContentCopyIcon /> }
    </IconButton>
  );
};
