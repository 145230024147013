import { FetchPolicy, gql, useMutation, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { configFragment } from "./fragment/configFragments";
import { Configuration } from "../models/config";
import { UserFragment } from "./fragment/userFragments";
import { User } from "../models/user";

export const GET_USER = gql`
  query($id: Int) {
    user(id: $id) {
      ...userFragment
    }
  }
  ${UserFragment}
`;

export function useUser(
  id = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error, loading, refetch } = useQuery<{ user: User | null }>(GET_USER, {
    variables: { id },
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });

  const user = useMemo(() => data?.user
    ?? { configuration: {} } as unknown as User, [data]);

  return { user, error, loading, refetch };
}

// --------------------------------------------------------
export const UPDATE_USER_CONFIG = gql`
  mutation updateUserConfig($input: UpdateUserConfigInput!) {
    updateUserConfig(input: $input) {
      ...configFragment
    }
  }
  ${configFragment}
`;

export function useUpdateUserConfig() {
  return useMutation<{ updateUserConfig: Configuration }>(UPDATE_USER_CONFIG, {
    refetchQueries: [
      GET_USER,
      "user", // Query name
    ],
  });
}
