import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { routes } from "./routes";

const navCards = ["aiGenerate", "aiImages", "aiImagesFav", "blueprintList", "billing"];

export function Home() {
  const navigate = useNavigate();

  const cards = useMemo(() => navCards.map(n => {
    const { description, imageUrl, newTab, name, route } = routes[n];
    return (
      <Grid xs={6} md={4} lg={3} key={name}>
        <Card
          size="md"
          sx={cardStyle}
          variant="outlined"
          onClick={() => {
            if (newTab) {
              window.open(route, "_blank");
              return;
            }
            navigate(route);
          }}
        >
          <Typography sx={cardTitleStyle}>{name}</Typography>
          <Typography textColor={"primary.400"} sx={cardSubtitleStyle}>{description}</Typography>
          <img src={imageUrl} alt={imageUrl} />
        </Card>
      </Grid>
    );
  }), [navigate]);

  return (
    <Box>
      <Typography color="primary" level="h1" sx={headerStyle}>Welcome to The Furniture App</Typography>
      <Typography sx={textBodyStyle}>
        {}
      </Typography>

      <Grid container spacing={2}>
        {cards}
      </Grid>
    </Box>
  );
}

const headerStyle = {
  textAlign: "center",
  my: "1rem",
};

const cardStyle = {
  cursor: "pointer",
  filter: "brightness(95%)",
  "&:hover": {
    boxShadow: "xl",
    filter: "brightness(100%)",
  },
};

const cardTitleStyle = {
  typography: {
    md: "h3",
    xs: "title-lg",
  },
};

const cardSubtitleStyle = {
  minHeight: "1.25rem",
};

const textBodyStyle = {
  mb: "1rem",
};
