import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import Option from "@mui/joy/Option";
import Paper from "@mui/material/Paper";
import Select from "@mui/joy/Select";
import SellIcon from "@mui/icons-material/Sell";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { IconButtonTooltip } from "./iconButtonTooltip";
import { BlueprintControls, BlueprintControlFields } from "./blueprintControls";
import { Checkout } from "./checkout";
import { BlueprintFields, FurnitureType } from "../models/blueprint";
import { routes } from "../pages/routes";
import { useCreateCheckoutSession, usePrices } from "../graphql/billing";
import { useCreateBlueprint, useFurnitureDesignSpecs } from "../graphql/blueprint";
import { useImages } from "../graphql/image";

export interface BlueprintOrderFormProps {
  imageId: string
}

export function BlueprintOrderForm(props: BlueprintOrderFormProps) {
  const { imageId } = props;
  const navigate = useNavigate();
  const [furnitureType, setFurnitureType] = useState<FurnitureType>();
  const [controls, setControls] = useState<BlueprintControlFields[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
  const [blueprintRoute, setBlueprintRoute] = useState<string>("");
  // A ref is used for embedded lambdas which don't see current state
  const blueprintValuesRef = useRef<BlueprintFields>({});
  const [blueprintValues, setBlueprintValues] = useState<BlueprintFields>({});
  const [createBlueprint] = useCreateBlueprint();
  const [createCheckoutSession, { data }] = useCreateCheckoutSession();
  const { clientSecret, id: sessionId } = data?.createCheckoutSession ?? {};
  const { /* prices , */ loading: priceLoading } = usePrices();
  const { images } = useImages({ ids: [imageId] });
  const furnImage = images?.[0];

  const {
    furnitureDesignSpecs, loading, // error, // ToDo: error handling
  } = useFurnitureDesignSpecs({ fetchPolicy: "network-only" });

  const isBusy = useMemo(() => loading || isActive || isSaving || priceLoading,
    [isActive, isSaving, loading, priceLoading]);

  const furnitureTypes = useMemo(() => (
    furnitureDesignSpecs.map(t => <Option value={t.type} key={t.label}>{t.label}</Option>)
  ), [furnitureDesignSpecs]);

  const onChange = useCallback((fieldName: string, value: any) => {
    blueprintValuesRef.current = { ...blueprintValuesRef.current, [fieldName]: value };
    // Must also update state to trigger child re-render
    setBlueprintValues(blueprintValuesRef.current);
  }, []);

  const onBlur = useCallback((fieldName: keyof BlueprintFields) => {
    let value = blueprintValuesRef.current[fieldName];
    if (typeof value === "string") {
      // Remove unnecessary spaces
      value = (blueprintValuesRef.current[fieldName] as string)?.trim();
    }
    onChange(fieldName, value);
  }, [onChange]);

  const furnitureTypeHandler = useCallback((event: any, furnType: any) => {
    setFurnitureType(furnType);
    // Clear values when furniture type changes
    blueprintValuesRef.current = {} as BlueprintFields;
    // Configure inputs for selected furniture type
    const furnitureFields = furnitureDesignSpecs.find(f => f.type === furnType)?.fields ?? [];
    setControls(furnitureFields.map(ff => {
      const { default: defaultValue, help, label, max, min, name, required, step, type, values } = ff;
      const fieldName = name as keyof BlueprintFields;
      if (defaultValue) {
        const value = type === "NUMBER" ? +defaultValue : defaultValue;
        blueprintValuesRef.current = { ...blueprintValuesRef.current, [fieldName]: value };
        setBlueprintValues(blueprintValuesRef.current);
      }
      return {
        defaultValue,
        max,
        min,
        onBlur: () => onBlur(fieldName),
        onChange: (value: any) => onChange(fieldName, value),
        options: values,
        placeholder: help,
        required,
        step,
        tip: help,
        label,
        type,
        value: blueprintValues[fieldName],
      };
    }));
  }, [blueprintValues, furnitureDesignSpecs, onBlur, onChange]);

  const handleOrderBlueprint = useCallback(async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsSaving(true);

    const result = await createBlueprint({
      variables: {
        input: {
          ...blueprintValuesRef.current,
          imageIds: [imageId],
          type: furnitureType,
        },
      },
    });

    const newBlueprintRoute = `${routes.blueprint.route}/${result?.data?.createBlueprint?.id}`;
    const newBlueprint = result?.data?.createBlueprint;
    if (newBlueprint) {
      if (!newBlueprint.hasPurchased) {
        setIsActive(true);
        setBlueprintRoute(newBlueprintRoute);
        await createCheckoutSession({
          variables: { input: { blueprintId: newBlueprint.id } },
        });
        return;
      }
    }
    else {
      // ToDo: show error
    }

    // Clear values after successfully creating blueprint
    blueprintValuesRef.current = {};
    navigate(newBlueprintRoute);
  }, [createBlueprint, createCheckoutSession, furnitureType, imageId, navigate]);

  const purchaseBox = useMemo(() => (
    <Checkout
      clientSecret={clientSecret}
      onOpenClose={isOpen => {
        setIsPurchaseOpen(isOpen);
        navigate(blueprintRoute);
      }}
      sessionId={sessionId}
    />
  ), [blueprintRoute, clientSecret, navigate, sessionId]);

  useEffect(() => {
    if (sessionId) {
      setIsPurchaseOpen(true);
      setIsActive(false);
    }
  }, [sessionId]);

  if (isPurchaseOpen) {
    // Purchase mode displays Stripe checkout page
    return (
      <Paper style={containerStyle} elevation={10} variant="outlined">
        {purchaseBox}
      </Paper>
    );
  }

  return (
    <Paper style={containerStyle} elevation={10} variant="outlined">
      <Sheet>
        <Card size="sm" variant="solid" color="neutral" invertedColors sx={titleCardStyle} >
          <Typography level="h1" fontWeight="var(--joy-fontWeight-md)">
            Order Blueprint Form
          </Typography>
        </Card>
        <Sheet style={furnitureTypeStyle}>
          <Select
            disabled={isBusy}
            onChange={furnitureTypeHandler}
            placeholder={"Choose furniture type"}
            value={furnitureType}
          >
            {furnitureTypes}
          </Select>
          <IconButtonTooltip isDisabled={isBusy} tip={"Select a furniture type"} />
          <img src={furnImage?.sourceUrl} alt={furnImage?.name} style={imgPreviewStyle} />
        </Sheet>
        { !!furnitureType && (
          <form onSubmit={handleOrderBlueprint}>
            <Grid container spacing={2} xs={10} style={gridContainerStyle}>
              <BlueprintControls
                controls={controls}
                isBusy={isBusy}
              />
              <Grid xs={12} md={6} style={gridCellStyle}>
                <Button type="submit" style={submitButtonStyle} disabled={isBusy}>
                  { isBusy
                    ? <CircularProgress size="lg"/>
                    : <SellIcon sx={sellIconStyle}/>
                  }
                  Order Blueprint
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Sheet>
    </Paper>
  );
}

const containerStyle = {
  boxshadow: 3,
  color: "danger",
  marginBottom: "2rem",
  padding: "0.5rem",
} as React.CSSProperties;

const titleCardStyle = {
  bgcolor: "neutral.900",
};

const furnitureTypeStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  marginBottom: "1rem",
  marginTop: "1rem",
} as React.CSSProperties;

const imgPreviewStyle = {
  maxHeight: "5rem",
};

const gridContainerStyle = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "1rem",
} as React.CSSProperties;

const gridCellStyle = {
  paddingTop: 0,
  paddingBottom: 0,
};

const submitButtonStyle = {
  marginTop: "2rem",
};

const sellIconStyle = {
  mr: 1,
};
